const PRESETCLASS = {
  CLASS_1: {
    value: 1,
    text: 'Turma 21 Segunda-Feira 8:30 as 9:30',
    numberOfVacancies: 20
  },

  CLASS_2: {
    value: 2,
    text: 'Turma 22 Segunda-Feira 10:00 as 11:00',
    numberOfVacancies: 20
  },

  CLASS_3: {
    value: 3,
    text: 'Turma 23 Quarta-Feira 8:30 as 9:30',
    numberOfVacancies: 20
  },

  CLASS_4: {
    value: 4,
    text: 'Turma 24 Quarta-Feira10:00 as 11:00',
    numberOfVacancies: 20
  },

  CLASS_5: {
    value: 5,
    text: 'Turma 25 Sexta-Feira 8:30 as 9:30',
    numberOfVacancies: 20
  },

  CLASS_6: {
    value: 6,
    text: 'Turma 26 Sexta-Feira 10:00 as 11:00',
    numberOfVacancies: 20
  },

  //

  CLASS_7: {
    value: 7,
    text: 'Turma 27 Segunda-Feira 14:00 as 15:00',
    numberOfVacancies: 20
  },

  CLASS_8: {
    value: 8,
    text: 'Turma 28 Segunda-Feira 15:30 as 16:30',
    numberOfVacancies: 20
  },

  CLASS_9: {
    value: 9,
    text: 'Turma 29 Quarta-Feira14:00 as 15:00',
    numberOfVacancies: 20
  },

  CLASS_10: {
    value: 10,
    text: 'Turma 30 Quarta-Feira15:30 as 16:30',
    numberOfVacancies: 20
  },

  CLASS_11: {
    value: 11,
    text: 'Turma 31 Sexta-Feira 14:00 as 15:00',
    numberOfVacancies: 20
  },

  CLASS_12: {
    value: 12,
    text: 'Turma 32 Sexta-Feira 15:30 as 16:30',
    numberOfVacancies: 20
  },

  //

  CLASS_13: {
    value: 13,
    text: 'Turma 33 Terça-Feira 8:00 as 9:30',
    numberOfVacancies: 30
  },

  CLASS_14: {
    value: 14,
    text: 'Turma 34 Terça-Feira 10:00 as 11:30',
    numberOfVacancies: 30
  },

  CLASS_15: {
    value: 15,
    text: 'Turma 35 Quinta-Feira 8:00 as 9:30',
    numberOfVacancies: 30
  },

  CLASS_16: {
    value: 16,
    text: 'Turma 36 Quinta-Feira 10:00 as 11:30',
    numberOfVacancies: 30
  },

  //

  CLASS_17: {
    value: 17,
    text: 'Turma 37 Terça-Feira 13:30 as 15:00',
    numberOfVacancies: 30
  },

  CLASS_18: {
    value: 18,
    text: 'Turma 38 Terça-Feira 15:30 as 17:00',
    numberOfVacancies: 30
  },

  CLASS_19: {
    value: 19,
    text: 'Turma 39 Quinta-Feira 13:30 as 15:00',
    numberOfVacancies: 30
  },

  CLASS_20: {
    value: 20,
    text: 'Turma 40 Quinta-Feira 15:30 as 17:00',
    numberOfVacancies: 30
  }
}

Object.freeze(PRESETCLASS)

export default PRESETCLASS
