import ModalityLocalTrainingModel from '@/shared/models/modalityLocalTraining'
import PRESET_CLASSES from '@/shared/enums/presetClasses'
import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalityLocalTraining'

const state = {
  modalityLocalTraining: new ModalityLocalTrainingModel(),
  ePresetClasses: Object.values(PRESET_CLASSES),
  modalitiesLocalTrainings: [],
  localTrainings: [],
  modalities: [],
  modalitiesLocals: [],
  filteredArray: [],
  optionsPresetClass: [],
  isNeedValidatePresetClass: true,
  year: null
}

const getters = {
  modalitiesLocals: state => state.modalitiesLocals,
  modalityLocalTraining: state => state.modalityLocalTraining,
  modalitiesLocalTrainings: state => state.modalitiesLocalTrainings,
  isNeedValidatePresetClass: state => state.isNeedValidatePresetClass
}

const mutations = {
  CLEAR_MODALITY_LOCALTRAINING: state => state.modalityLocalTraining = new ModalityLocalTrainingModel(),

  CLEAR_MODALITIES_LOCALS: state => state.modalitiesLocals = [],

  REMOVE_MODALITY_LOCAL: (state, payload) => state.modalitiesLocals.splice(payload, 1),

  SET_MODALITY: (state, payload) => state.modalityLocalTraining.modalityId = payload,

  SET_LOCAL_TRAINING: (state, payload) => state.modalityLocalTraining.localTrainingId = payload,

  SET_MODALITIES_LOCAL_TRAININGS: (state, payload) => state.modalitiesLocalTrainings = payload,

  CLEAR_MODALITIES: state => state.modalities = [],

  SET_MODALITIES: (state, payload) => state.modalities.push(payload),

  CLEAR_LOCAL_TRAININGS: state => state.localTrainings = [],

  SET_LOCAL_TRAININGS: (state, payload) => state.localTrainings.push(payload),

  SET_OPTIONS_PRESET_CLASS: (state, payload) => state.optionsPresetClass.push(payload),

  CLEAR_OPTIONS_PRESET_CLASS: state => state.optionsPresetClass = [],

  PUSH_MODALITY_LOCAL: (state, payload) => {
    const localTrainingName = state.localTrainings.find(lt => lt.localTrainingId === payload.localTrainingId).localTrainingName
    const modalityName = state.modalities.find(m => m.modalityId === payload.modalityId).modalityName
    const presetClass = state.optionsPresetClass.find(m => m.text === payload.presetClass)

    if (localTrainingName && modalityName || (state.isNeedValidatePresetClass && presetClass)) {
      payload.localTrainingName = localTrainingName
      payload.modalityName = modalityName
      payload.presetClass = presetClass?.text
      payload.totalVacancies = presetClass?.numberOfVacancies
      return state.modalitiesLocals.push(payload)
    }
  },

  SET_YEAR: (state, payload) => {

    if (payload) {
      console.log('payload', payload)
      return state.year = payload
    }
  },

  PUSH_ML_FILTERED: (state, payload) => {
    payload.forEach(item => {
      state.filteredArray.push(item)
    })

    return state.filteredArray
  },

  CLEAR_ML_FILTERED: state => state.filteredArray = []
}

const actions = {
  async fetchModalitiesLocalTrainings({ state, commit, dispatch }, force) {
    if (!state.modalitiesLocalTrainings.length || force) {
      dispatch('commonModule/showLoading', true, { root: true })

      await ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!res.data.data) return Promise.reject()

          commit('SET_MODALITIES_LOCAL_TRAININGS', res.data.data)
          dispatch('commonModule/showLoading', false, { root: true })
          return Promise.resolve()
        })
        .catch(err => {
          dispatch('commonModule/toast', {
            type: 'error',
            msg: err,
            title: 'Erro'
          }, { root: true })

          dispatch('commonModule/showLoading', false, { root: true })
          return Promise.reject()
        })
    }
    return Promise.resolve()
  },

  onClearModalityAndLocalTrainingOptions({ state, commit }) {
    if (state.modalities.length > 0)
      commit('CLEAR_MODALITIES')

    if (state.localTrainings.length > 0)
      commit('CLEAR_LOCAL_TRAININGS')
  },

  onSelectLocalTraining({ state, commit }) {
    let list = state.filteredArray

    if (state.modalityLocalTraining.localTrainingId) {
      const type = state.localTrainings.filter(lt => lt.localTrainingId === state.modalityLocalTraining.localTrainingId)

      if (type.length > 0) {
        state.modalityLocalTraining.localTrainingName = type[0].localTrainingName
        list = state.filteredArray.filter(lt => lt.localTrainingId === type[0].localTrainingId)
      }
    }

    commit('CLEAR_MODALITIES')
    for (let i = 0; i < list.length; i++) {
      let modality = {
        modalityId: list[i].modalityId,
        modalityName: list[i].modalityName
      }

      if (state.modalities.filter(m => m.modalityId === modality.modalityId).length === 0) {
        commit('SET_MODALITIES', modality)
      }
    }
  },

  onSelectModality({ state, commit }) {
    let list = state.filteredArray
    if (state.modalityLocalTraining.modalityId) {
      let type = state.modalities.filter(m => m.modalityId === state.modalityLocalTraining.modalityId)

      if (type.length > 0) {
        state.modalityLocalTraining.modalityName = type[0].modalityName
        list = state.filteredArray.filter(m => m.modalityId === type[0].modalityId)
      }
    }

    commit('CLEAR_LOCAL_TRAININGS')
    for (let i = 0; i < list.length; i++) {
      let local = {
        localTrainingId: list[i].localTrainingId,
        localTrainingName: list[i].localTrainingName
      }

      if (state.localTrainings.filter(lt => lt.localTrainingId === local.localTrainingId).length === 0) {
        commit('SET_LOCAL_TRAININGS', local)
      }
    }
  },

  onFilteredOptionsPresetClass({ state, commit }, payload) {
    let presetClasses = state.ePresetClasses

    const userChose = {
      schoolTerm: payload,
      localTrainingName: state.modalityLocalTraining.localTrainingName,
      modalityName: state.modalityLocalTraining.modalityName,
      year: state.year
    }

    commit('CLEAR_OPTIONS_PRESET_CLASS')
    if (userChose.schoolTerm == 1 && userChose.modalityName == 'INFORMATICA BASICA' && userChose.localTrainingName == 'ARENA') {
      presetClasses.filter(x => x.value >= 1 && x.value <= 6).forEach(item => {
        commit('SET_OPTIONS_PRESET_CLASS', item)
      })

      return
    }

    if (userChose.schoolTerm == 0 && userChose.modalityName == 'INFORMATICA BASICA' && userChose.localTrainingName == 'ARENA') {
      presetClasses.filter(x => x.value >= 7 && x.value <= 12).forEach(item => {
        commit('SET_OPTIONS_PRESET_CLASS', item)
      })

      return
    }

    if (userChose.schoolTerm == 1 && userChose.modalityName == 'ROBOTICA INFANTIL' && userChose.localTrainingName == 'ARENA') {
      presetClasses.filter(x => x.value >= 13 && x.value <= 16).forEach(item => {
        commit('SET_OPTIONS_PRESET_CLASS', item)
      })

      return
    }

    if (userChose.schoolTerm == 0 && userChose.modalityName == 'ROBOTICA INFANTIL' && userChose.localTrainingName == 'ARENA') {
      presetClasses.filter(x => x.value >= 17 && x.value <= 20).forEach(item => {
        commit('SET_OPTIONS_PRESET_CLASS', item)
      })

      return
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
